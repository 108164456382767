<template>
    <v-container
        v-resize="onResize"
        fluid
        class="overflow-y-hidden pa-0"
        ref="container"
        style="height:100%"
    >
        <v-card
            flat
            :class="{
                'overflow-x-auto': $vuetify.breakpoint.smAndDown,
                '': $vuetify.breakpoint.mdAndUp,
            }"
        >
            <div>
                <v-row class="flex-nowrap ma-0">
                    <v-col
                        class="pl-2 pr-0 py-0 ma-0"
                        :class="{ 'sticky-first': user.id === '123' }"
                        v-for="user of filterUsers"
                        :key="user.id"
                        cols="8"
                        xl="3"
                        lg="3"
                        md="5"
                    >
                        <CostUserDraggable
                            :height="height"
                            :user="user"
                            :users="users"
                            :settings="settings"
                            @emitCost="emitCost"
                            @resetUser="resetUser"
                            @removeCost="removeCost"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import API from '@/services/api'
import CostUserDraggable from '@/components/Engineering/CostUserDraggable.vue'
import moment from 'moment'
import { mapMutations } from 'vuex'

export default {
    name: 'EngineeringAssing',
    components: {
        CostUserDraggable,
    },
    data: () => ({
        loading: false,
        users: [
            {
                id: '123',
                name: 'Pending',
                costs: [],
            },
        ],
        height: 0,
        companyId: JSON.parse(localStorage.getItem('company')),
        userId: JSON.parse(localStorage.getItem('userId')),
        settings: [],
        quotesArray: [],
        quotesUsers: [],
        quotesUsersId: [],
        quotes: [],
        costs: [],
        usersBD: [],
    }),
    computed: {
        filterUsers() {
            return this.users
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.onResize()
            const {
                data: { settings },
            } = await API.getSettings()
            this.settings = settings
            await this.loadData(['OPEN', 'APPROVAL'])
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },

    updated() {
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
            } = this.$refs
            this.height = containerHeight + 19
        },
        async loadData(status) {
            try {
                this.loading = true
                this.menu = false

                const {
                    data: { users },
                } = await API.getUsers()
                this.usersBD = users
                users.forEach(user => {
                    if (
                        user.permissions &&
                        user.permissions.includes('uploadPreDesign')
                    ) {
                        this.users.push({ ...user, costs: [] })
                    }
                })
                //set initial dates
                this.endDate = new Date()
                this.endDate = this.endDate.toISOString().split('T')[0]

                let {
                    data: { quotes },
                } = await API.getSimpleQuotesByStatus(status)
                quotes.forEach(quote => {
                    const indexExchanges = this.settings.findIndex(
                        u => u.name === 'Exchange'
                    )
                    const { exchanges, globalCurrency } = this.settings[
                        indexExchanges
                    ]
                    quote.costExchanges = {
                        exchanges,
                        globalCurrency,
                    }
                    if (quote.userId) {
                        quote.mainContact = this.users.find(
                            user => quote.userId == user.id
                        )
                    }
                    quote.createdOn = quote.createdOn ? quote.createdOn : null
                })

                quotes.sort(
                    (a, b) => a.createdOn._seconds - b.createdOn._seconds
                )
                let index = quotes.findIndex(q => q.createdOn)
                this.startDate = this.formatDate(
                    quotes[index].createdOn._seconds ||
                        quotes[index].createdOn.seconds
                )
                let {
                    data: { costs },
                } = await API.getLiteCostsBigQ(this.startDate, this.endDate)
                costs.forEach(cost => {
                    if (!cost.engineer) {
                        cost.engineer = '123'
                    }
                    cost.quoteId = cost.document_name.split('/')[8]
                    cost.quote = quotes.find(quote => quote.id == cost.quoteId)

                    if (cost.quote) {
                        cost.quote.lastUpdate = cost.quote.updatedOn
                        cost.createdOn = cost.createdOn
                            ? this.formatDate(cost.createdOn._seconds)
                            : null
                        cost.updatedOn = cost.updatedOn
                            ? this.formatDate(cost.updatedOn._seconds)
                            : null
                    }
                })
                costs = costs.filter(cost => cost.quote !== undefined)
                costs.sort(
                    (a, b) =>
                        b.quote.updatedOn._seconds - a.quote.updatedOn._seconds
                )
                //Only leave quotes with costs
                quotes.forEach(quote => {
                    let exist = costs.find(cost => cost.quoteId == quote.id)
                    if (exist) {
                        this.quotesArray.push({
                            name: quote.name,
                            id: quote.id,
                        })
                        if (quote.estimatorId) {
                            quote.estimatorName = this.getUserName(
                                quote.estimatorId
                            )
                            this.quotesUsersId.push(quote.estimatorId)
                        }
                        if (quote.userId) {
                            this.quotesUsersId.push(quote.userId)
                        }
                    } else {
                        // quotes = quotes.filter(q => q.id != quote.id)
                    }
                })
                this.quotesUsersId = [...new Set(this.quotesUsersId)]
                this.quotesUsersId.forEach(user =>
                    this.quotesUsers.push({
                        id: user,
                        name: this.getUserName(user),
                    })
                )
                if (this.quotes.length > 0)
                    this.quotes = this.quotes.concat(quotes)
                else this.quotes = quotes

                if (this.costs.length > 0) this.costs = this.costs.concat(costs)
                else this.costs = costs
                this.costs = this.costs.filter(cost => cost.engineering)
                const costsByEngineerings = this.costs.reduce(
                    (acc, current) => {
                        const user =
                            current.engineer == '123'
                                ? current.engineer
                                : current.engineer
                        if (!acc[user]) {
                            acc[user] = {
                                id: user == '123' ? '123' : user,
                                costs: [],
                            }
                        }
                        acc[user].costs.push(current)

                        return acc
                    },
                    {}
                )
                this.users.forEach(user => {
                    user.costs = user.costs ? user.costs : []
                    user.costs =
                        costsByEngineerings[user.id] &&
                        costsByEngineerings[user.id].costs
                            ? costsByEngineerings[user.id].costs
                            : []
                    if (user.id !== '123' && user.id !== '') {
                        user.costs.sort((a, b) => a.indexAssign - b.indexAssign)
                    } else {
                        user.costs.sort(
                            (a, b) =>
                                a.engineeringDate._seconds -
                                b.engineeringDate._seconds
                        )
                    }
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        formatDate(seconds = undefined) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        getUserName(id) {
            if (this.usersBD.length > 0 && id) {
                return this.usersBD.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        emitCost(cost) {
            let userIndex = -1
            if (cost.engineer === '') {
                cost.engineer = '123'
            }
            userIndex = this.users.findIndex(user => user.id == cost.engineer)
            if (userIndex > -1) {
                this.users[userIndex].costs.push(cost)

                if (
                    this.users[userIndex].id != '123' &&
                    this.users[userIndex].id != ''
                ) {
                    this.users[userIndex].costs.sort(
                        (a, b) => a.indexAssign - b.indexAssign
                    )
                } else {
                    this.users[userIndex].costs.sort(
                        (a, b) =>
                            a.engineeringDate._seconds -
                            b.engineeringDate._seconds
                    )
                }
            }
        },
        removeCost(cost) {
            const userIndex = this.users.findIndex(
                user => user.id == cost.engineer
            )
            const index = this.users[userIndex].costs.findIndex(
                c =>
                    (c.document_id == cost.costId ||
                        c.document_id == cost.id) &&
                    c.quoteId == cost.quoteId
            )
            if (userIndex >= 0) {
                this.users[userIndex].costs.splice(index, 1)
            }
            const index2 = this.costs.findIndex(
                c =>
                    (c.document_id == cost.costId ||
                        c.document_id == cost.id) &&
                    c.quoteId == cost.quoteId
            )
            if (index2 >= 0) {
                this.costs.splice(index2, 1)
            }
        },
        resetUser(cost) {
            const userIndex = this.users.findIndex(
                user => user.id == cost.engineer
            )
            const index = this.users[userIndex].costs.findIndex(
                c =>
                    (c.document_id == cost.costId ||
                        c.document_id == cost.id) &&
                    c.quoteId == cost.quoteId
            )
            if (userIndex >= 0) {
                this.users[userIndex].costs.splice(index, 1)
            }
            const index2 = this.costs.findIndex(
                c =>
                    (c.document_id == cost.costId ||
                        c.document_id == cost.id) &&
                    c.quoteId == cost.quoteId
            )
            if (index2 >= 0) {
                this.costs.splice(index2, 1)
            }
        },
    },
}
</script>

<style>
.sticky-first {
    position: sticky;
    left: 0;
    z-index: 10;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}
</style>
